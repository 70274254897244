import React from "react";
import { IconMenu } from "../../IU/IconMenu/IconMenu";

export const BarMenu = () => {

  return (
    <div>
      <IconMenu />
    </div>
  );
};
