import React from 'react'
import { IconMenuStudent } from '../../IU/IconMenuStudent/IconMenuStudent'

export const BarMenuStudent = () => {
  return (
    <div>
      <IconMenuStudent/>
    </div>
  )
}
